.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.main-map-container {
  height: 100%;
  width: 100%;
}

.map-container {
  height: 100%;
  width: 50%;
  float: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.props-window {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 200px;
  background-color: #fff;
  z-index: 10;
  padding: 10px;
  border: solid 1px #ccc;
  border-radius: 2px;
}
.props-window label {
  font-size: 0.9em;
  font-weight: 600;
}
.props-window select {
  width: 100%;
}

.colorRef {
  width: 170px;
}
.colorRef div {
  clear: both;
}
.colorRef div>span {
  border: solid 2px #ff0000;
  width: 10px;
  height: 10px;
  display: block;
  float: left;
  margin-right: 5px;
  margin-bottom: 3px;
}
.colorRef div>label {
  text-transform: uppercase;
  float: left;
  font-size: 0.75em;
  font-weight: 500;
}
